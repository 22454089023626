import API from '../../configs/api';
import apiMethod from '../../utility/ApiMethod';

export const getListCity = (payload) => {
  return apiMethod.get(API.GET_COMMON_GET_CITY, payload);
};

export const getListDistrict = (payload) => {
  return apiMethod.get(API.GET_COMMON_GET_DISTRICT, payload);
};

export const getShareInfoData = async (codeLanguage = 'vi-VN') => {
  try {
    const { data } = await apiMethod.get(
      `/${codeLanguage}/${API.GET_SHARE_INFO}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};
